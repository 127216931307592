import { AxiosRequestConfig } from 'axios';
import { Study } from '@/models';
import apiClient from '@/services/api/client/apiClient';

export class ExpertModeRequestScansService {
  private endpoint: string;

  constructor(requestId: string) {
    this.endpoint = `/menicon/v1/expert-mode/encounter-requested-advices/${requestId}/studies`;
  }

  async getStudies(params: AxiosRequestConfig = {}): Promise<Study[]> {
    return (await apiClient.get(this.endpoint, params)).data;
  }
}
