import { FormSubmission } from '@/models';
import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class ExpertModeRequestFormSubmissionService {
  private endpoint = '/menicon/v1/expert-mode/form-submissions';

  async fetch(id: string | number, params: AxiosRequestConfig = {}): Promise<FormSubmission> {
    return (await apiClient.get(`${this.endpoint}/${id}`, params)).data;
  }
}
