import { GenericService } from '@/services/api/generic.service';
import { PaginatedResponse } from '@/models';
import { Message, Thread, MessageData } from '@/custom/menicon/models';
import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class ThreadService extends GenericService<Thread> {

  constructor() {
    super('menicon/v1/threads');
  }

  // @ts-ignore
  async fetch(id: string | number, params: AxiosRequestConfig = {}): Promise<PaginatedResponse<Thread>> {
    return await apiClient.get(`${this.endpoint}/${id}`, params);
  }

  async createMessage(
    data: MessageData,
    params: AxiosRequestConfig = {}
  ): Promise<Message> {
    return (await apiClient.post(`${this.endpoint}/${data.thread_id}/messages`, data, params)).data;
  }

  // @ts-ignore
  async update(
    id: string | number,
    changes: {
      org_unit_id: string;
      closed: boolean;
    },
    params: AxiosRequestConfig = {}
  ): Promise<Thread> {
    // @ts-ignore
    return await super.update(id, changes, params);
  }
}
