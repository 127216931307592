import router from '@/router';
import { useSessionStore } from '@/stores/session.store';

const directive = {
  mounted: (el, binding) => {
    if (!binding.value) {
      return;
    }
    const removeChild = document.createComment(' ');
    const sessionStore = useSessionStore();
    const { currentUser, permissions } = sessionStore;
    if (!(router.currentRoute.value.meta?.isAdmin && (currentUser?.is_admin || currentUser?.is_lens_support))) {
      if (binding.value?.length && !permissions.includes(binding.value)) {
        el.parentNode?.replaceChild(removeChild, el);
      } else if (removeChild.parentNode) {
        removeChild.parentNode.replaceChild(el, removeChild);
      }
    }
    el.unwatch = sessionStore.$subscribe(() => {
      if (!(router.currentRoute.value.meta?.isAdmin && (currentUser?.is_admin || currentUser?.is_lens_support))) {
        if (binding.value?.length && !sessionStore.permissions.includes(binding.value)) {
          el.parentNode?.replaceChild(removeChild, el);
        } else if (removeChild.parentNode) {
          removeChild.parentNode.replaceChild(el, removeChild);
        }
      }
    });
  },
  unmounted: (el) => {
    if (el.unwatch) {
      el.unwatch();
    }
  }
};

export default directive;
