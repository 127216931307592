export enum CustomerBranding {
  BPM = 'big-picture-medical',
  MENICON = 'menicon',
  JNJ = 'jnj'
}

export interface Customer {
  id: string;
  name: string;
  urls: CustomerUrl[];
  primary_url: string;
  legal_regions: string[];
  platform_name: string;
  branding: CustomerBranding;
  has_terms_of_service: boolean;
  download_patient_data?: boolean;
  is_admin: boolean;
}

export interface CustomerUrl {
  id: string;
  url: string;
  primary: boolean;
}
