import { GenericService } from '@/services/api/generic.service';
import { EncounterRequestedAdvice } from '@/custom/menicon/models';
import { AxiosRequestConfig } from 'axios';
import { Organisation } from '@/models';
import apiClient from '@/services/api/client/apiClient';

export class ExpertModeRequestService extends GenericService<EncounterRequestedAdvice> {
  constructor() {
    super('/menicon/v1/expert-mode/encounter-requested-advices');
  }

  async getOrganisations(params: AxiosRequestConfig = {}): Promise<Organisation[]> {
    return (await apiClient.get(`/menicon/v1/expert-mode/organisations`, params)).data;
  }
}
