import { defineStore } from 'pinia';
import { CustomerBranding } from '@/models';

interface State {
  loading: boolean;
  bodyScrollLock: boolean;
  headerBranding: CustomerBranding | null;
}

export const useUiStore = defineStore('ui', {
  state: (): State => ({
    loading: false,
    bodyScrollLock: false,
    headerBranding: null
  })
});
