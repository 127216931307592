import { ExternalPatientReferenceType } from '@/models';
import { defineStore } from 'pinia';
import { ExternalPatientReferenceTypeService } from '@/services/api';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { computed, ref } from 'vue';

const externalPatientReferenceTypeService = new ExternalPatientReferenceTypeService();

export const usePatientStore = defineStore('patient', () => {
  const externalPatientReferenceTypes = ref<ExternalPatientReferenceType[]>([]);
  const externalPatientReferenceTypesLoaded = ref(false);

  const showNhsNumber = computed<boolean>(() =>
    !!externalPatientReferenceTypes.value.find((type) => type.key === UHB_NHS_NUMBER_KEY)
  );

  async function fetchExternalPatientReferenceTypes() {
    if (!externalPatientReferenceTypesLoaded.value) {
      try {
        externalPatientReferenceTypes.value = (await externalPatientReferenceTypeService.index()).data;
        externalPatientReferenceTypesLoaded.value = true;
      } catch (e) {
        console.error(e);
      }
    }
  }

  fetchExternalPatientReferenceTypes();

  return {
    showNhsNumber,
    externalPatientReferenceTypes,
    fetchExternalPatientReferenceTypes
  };
});
