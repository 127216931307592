import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { i18n } from '@/i18n/i18n';
import { useNotificationStore } from '@/stores/notification.store';
import { useSessionStore } from '@/stores/session.store';
import logoutService from '@/services/auth/logout.service';

export function handleErrorResponse(error: AxiosError | Error) {
  const sessionStore = useSessionStore();
  if (axios.isAxiosError(error) && error.response) {
    if (error.config.params?.ignoreError) {
      return;
    }

    const { status } = error.response;

    // Server Errors
    if (status >= 500) {
      // showErrorNotificationFromResponse(error);
    }

    // Unauthorised
    if (status === 401) {
      logoutService(false);
      if (error.response.data.error === 'password_reset_required') {
        throw error;
      }
    }

    // Forbidden
    if (status === 403) {
      Sentry.captureException(error);

      useNotificationStore().snackbar = {
        label: i18n.global.t('platform.error.403'),
        autoDismiss: true,
        dismissAfter: 6000,
        color: 'danger',
        icon: 'warning'
      };
    }
    // Maintenance
    if (status === 503) {
      sessionStore.isMaintenance = true;
    }

    // Conflict
    if (status === 409) {
      // showErrorNotificationFromResponse(error);
    }
  } else {
    Sentry.captureException(error);
  }
}
