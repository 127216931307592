import { DateTimeFormats as IntlDateTimeFormats } from '@intlify/core-base';

export const datetimeFormats: IntlDateTimeFormats = {
  'en-GB': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h12',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h12'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'de-DE': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'fr-FR': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'ro-RO': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'fr-CA': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'es-ES': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'en-US': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h12',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h12'
    }
  }
};
