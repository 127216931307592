import { DifferenceMapsPattern, Encounter, Laterality } from '@/custom/menicon/models';
import { DEFAULT_ADDITIONAL_CORRECTION, DifferenceMapsAssessment } from '@/custom/menicon/constants';

export const getDifferenceMapsAssessment = (
  treatmentZoneAsExpected?: boolean,
  centrationAsExpected?: boolean,
  pattern?: string
) => {
  if (centrationAsExpected === false) {
    return DifferenceMapsAssessment.SMILEY_FACE;
  }

  if (treatmentZoneAsExpected === false && centrationAsExpected === true && pattern === DifferenceMapsPattern.B) {
    return DifferenceMapsAssessment.CENTRAL_ISLAND;
  }

  return DifferenceMapsAssessment.BULLS_EYE;
};

export const getOrderNewLens = (
  value: Partial<Encounter>,
  lateralities: Laterality[] = [Laterality.right, Laterality.left]
): boolean => {
  if (!value) {
    return true;
  }

  const rightEyeConditions =
    (value.r_is_expected_treatment_zone === true && value.r_is_expected_centration === true) ||
    (value.r_is_expected_treatment_zone === false &&
      value.r_is_expected_centration === true &&
      value.r_pattern === DifferenceMapsPattern.A);

  const leftEyeConditions =
    (value.l_is_expected_treatment_zone === true && value.l_is_expected_centration === true) ||
    (value.l_is_expected_treatment_zone === false &&
      value.l_is_expected_centration === true &&
      value.l_pattern === DifferenceMapsPattern.A);

  return !lateralities.reduce(
    (acc, laterality) => acc && (laterality === Laterality.right ? rightEyeConditions : leftEyeConditions),
    true
  );
};

export const isDifferenceMapsDifferentForLaterality =
  (encounter1: Partial<Encounter>, encounter2: Partial<Encounter>, laterality: Laterality = Laterality.right) => {
    switch (laterality) {
    case Laterality.right:
      return getDifferenceMapsAssessment(
        encounter1.r_is_expected_treatment_zone,
        encounter1.r_is_expected_centration,
        encounter1.r_pattern) !== getDifferenceMapsAssessment(
        encounter2.r_is_expected_treatment_zone,
        encounter2.r_is_expected_centration,
        encounter2.r_pattern);
    case Laterality.left:
      return getDifferenceMapsAssessment(
        encounter1.l_is_expected_treatment_zone,
        encounter1.l_is_expected_centration,
        encounter1.l_pattern) !== getDifferenceMapsAssessment(
        encounter2.l_is_expected_treatment_zone,
        encounter2.l_is_expected_centration,
        encounter2.l_pattern);
    }
  };

export const getPowerCorrectionOnLensSupportDifferenceMapsAnswersUpdate =
  (encounter: Encounter, answer: Partial<Encounter>): Record<Laterality, string> => {
    let right = Number(encounter.r_power_correction).toFixed(2);
    let left = Number(encounter.l_power_correction).toFixed(2);
    if (isDifferenceMapsDifferentForLaterality(encounter, answer)) {
      right = DEFAULT_ADDITIONAL_CORRECTION;
    }
    if (isDifferenceMapsDifferentForLaterality(encounter, answer, Laterality.left)) {
      left = DEFAULT_ADDITIONAL_CORRECTION;
    }
    return { right, left };
  };
