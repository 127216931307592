import { LocaleMessages, createI18n, VueMessageType } from 'vue-i18n/index';
import { datetimeFormats } from './date-time-formats';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/ja';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/ro';
import mergeWith from 'lodash-es/mergeWith';
import { allLocales as medKitLocaleMessages } from '@bigpicturemedical/medkit';

export interface SupportedLocale {
  name: string;
  code: string;
  dayjsCode: string;
  is24hr: boolean;
  masks: {
    date: string;
    dateTime: string;
  };
}

export const supportedLocales: SupportedLocale[] = [
  {
    name: 'English (United Kingdom)',
    code: 'en-GB',
    is24hr: false,
    dayjsCode: 'en-gb',
    masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY hh:mm A' }
  },
  {
    name: 'English (United States)',
    code: 'en-US',
    is24hr: false,
    dayjsCode: 'en',
    masks: { date: 'MM/DD/YYYY', dateTime: 'DD/MM/YYYY hh:mm A' }
  },
  {
    name: 'Japanese (日本語)',
    code: 'ja-JP',
    is24hr: true,
    dayjsCode: 'ja',
    masks: { date: 'YYYY/MM/DD', dateTime: 'YYYY/MM/DD HH:mm' }
  },
  {
    name: 'Deutsch',
    code: 'de-DE',
    is24hr: true,
    dayjsCode: 'de',
    masks: { date: 'DD.MM.YYYY', dateTime: 'DD.MM.YYYY HH:mm' }
  },
  {
    name: 'Français',
    code: 'fr-FR',
    is24hr: true,
    dayjsCode: 'fr',
    masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY HH:mm' }
  },
  {
    name: 'Français (Canada)',
    code: 'fr-CA',
    is24hr: true,
    dayjsCode: 'fr',
    masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY HH:mm' }
  },
  {
    name: 'Español',
    code: 'es-ES',
    is24hr: true,
    dayjsCode: 'es',
    masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY HH:mm' }
  },
  {
    name: 'Italiano',
    code: 'it-IT',
    is24hr: true,
    dayjsCode: 'it',
    masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY HH:mm' }
  }
  // TODO: enable romanian once translations are available
  // {
  //   name: 'Română',
  //   code: 'ro-RO',
  //   is24hr: true,
  //   dayjsCode: 'ro',
  //   masks: { date: 'DD/MM/YYYY', dateTime: 'DD/MM/YYYY HH:mm' }
  // }
];

export function loadLocaleMessages(): LocaleMessages<VueMessageType> | undefined {
  const messages: LocaleMessages = {};

  for (let i = 0; i < supportedLocales.length; i++) {
    const langCode = supportedLocales[i].code;
    messages[langCode] = require(/* webpackChunkName: "lang-[request]" */ `@/locales/bundle/${langCode}.json`);
  }
  return messages;
}

export const isLocaleSupported = (locale: string) => !!Object.values(supportedLocales)
  .find((supportedLocale) => supportedLocale.code === locale);
export const getDefaultLocale = () => [process.env.VUE_APP_I18N_LOCALE, navigator.languages?.[0] ?? navigator.language].find(isLocaleSupported) ?? 'en-GB';

export const i18n = createI18n({
  locale: getDefaultLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-GB',
  messages: mergeWith(loadLocaleMessages(), medKitLocaleMessages),
  legacy: false,
  globalInjection: true,
  datetimeFormats
});

export const switchLocale = (locale: string) => {
  if (i18n.global.locale.value !== locale) {
    const docHtml = document.querySelector('html');
    if (docHtml) {
      docHtml.setAttribute('lang', locale);
    }
    i18n.global.locale.value = locale;
    const supportedLocale = supportedLocales.find((localeObj) => localeObj.code === locale);
    if (supportedLocale) {
      dayjs.locale(supportedLocale.dayjsCode);
    }
  }
};
