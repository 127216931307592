import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { PaginatedResponse } from '@/models';

interface IGenericService<T> {
  index(params: AxiosRequestConfig): Promise<AxiosResponse<Array<T>> | PaginatedResponse<Array<T>>>;
  fetch(id: string | number, params: AxiosRequestConfig): Promise<T>;
  create(model: Partial<T>, params: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  update(id: string | number, changes: Partial<T>, params: AxiosRequestConfig): Promise<T>;
  delete(id: string | number, params: AxiosRequestConfig): Promise<T>;
}

export class GenericService<T> implements IGenericService<T> {

  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async index(params: AxiosRequestConfig = {}): Promise<AxiosResponse<Array<T>> | PaginatedResponse<Array<T>>> {
    return await apiClient.get(this.endpoint, params);
  }

  async fetch(id: string | number, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.get(`${this.endpoint}/${id}`, params)).data;
  }

  async create(model: Partial<T>, params: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await apiClient.post(this.endpoint, model, params);
  }

  async update(id: string | number, changes: Partial<T>, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.patch(`${this.endpoint}/${id}`, changes, params)).data;
  }

  async delete(id: string | number, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.delete(`${this.endpoint}/${id}`, params)).data;
  }
}
