import { ISuggestion } from '@/lib';

export const uniq = (array: Array<ISuggestion>): Array<ISuggestion> =>
  array.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.label === item.label));

export const getSuggestions = (items: Array<ISuggestion>, search: string): ISuggestion[] =>
  search
    ? search.length
      ? uniq(
        items.filter(
          (suggestion: ISuggestion) =>
            suggestion.label.toLowerCase().includes(search.toLowerCase()) && suggestion.label !== search
        )
      )
      : []
    : uniq(items);
