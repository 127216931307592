export const ENCOUNTER_FILTER_STORAGE_KEY = 'encounter';
export const ENCOUNTER_SORT_STORAGE_KEY = 'encounter-sort';
export const JOURNEY_FILTER_STORAGE_KEY = 'journey';
export const JOURNEY_SORT_STORAGE_KEY = 'journey-sort';
export const REVISION_FILTER_STORAGE_KEY = 'revision';
export const REVISION_SORT_STORAGE_KEY = 'revision-sort';
export const REQUEST_SORT_STORAGE_KEY = 'request-sort';
export const REQUEST_SORT_COMPLETED_STORAGE_KEY = 'request-completed-sort';
export const REQUEST_STATUS_STORAGE_KEY = 'request-status';
export const REQUEST_ORGANISATION_STORAGE_KEY = 'request-organisation';

export const STORAGE_KEYS = [
  ENCOUNTER_FILTER_STORAGE_KEY,
  ENCOUNTER_SORT_STORAGE_KEY,
  JOURNEY_FILTER_STORAGE_KEY,
  REVISION_FILTER_STORAGE_KEY,
  JOURNEY_SORT_STORAGE_KEY,
  REVISION_SORT_STORAGE_KEY,
  REQUEST_SORT_STORAGE_KEY,
  REQUEST_SORT_COMPLETED_STORAGE_KEY,
  REQUEST_STATUS_STORAGE_KEY,
  REQUEST_ORGANISATION_STORAGE_KEY
];
