import { supportedLocales } from '@/i18n/i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const localisedMask = (localeCode: string, mode = 'date'): string => {
  const defaultInputMask = 'DD/MM/YYYY';
  const supportedLocale = supportedLocales.find(({ code }) => code === localeCode);

  return supportedLocale ? supportedLocale.masks[mode] : defaultInputMask;
};

export const timeFromNow = (date: string) => dayjs(date).fromNow();
