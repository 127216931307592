import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { MeniconCornealDetailsRequestModel, MeniconCornealDetailsResponse } from '../models';

export class MeniconCornealDetailsService {
  async getCornealDetails(params: MeniconCornealDetailsRequestModel, config: AxiosRequestConfig = {}):
  Promise<AxiosResponse<MeniconCornealDetailsResponse>> {
    return await apiClient.get('/menicon/v1/corneal-details', {
      ...config,
      params,
      timeout: 200000
    });
  }
}
