import { FormItem, FormSection, Patient } from '@/models';
import { Encounter, Journey } from '@/custom/menicon/models';
import { i18n } from '@/i18n/i18n';
import {
  EncounterGridSummaryData,
  EncounterQuestionSummaryData, EncounterStringSummaryData, EncounterTableSummaryData,
  formatFormSchemaAnswer
} from '@/custom/menicon/helpers/form-summary.helper';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { isRepeaterAnswer } from '@/helpers/smart-form.helper';

export const getPrescriptionDetailsCopyableText =
  (patient: Patient, journey: Journey, encounter: Encounter, locale = i18n.global.locale.value): string => {
    const prescriptionDetails = i18n.global.t('custom.menicon.prescriptions.history.prescription-details', [], { locale });
    const patientName = `${i18n.global.t('platform.patient.patient-name', [], { locale })}: ${patient.last_name.toUpperCase()}, ${patient.first_name}`;
    const dateOfBirth = `${i18n.global.t('platform.patient.date-of-birth', [], { locale })}: ${i18n.global.d(patient.date_of_birth, 'shortMonth', locale)}`;
    const patientId = `${i18n.global.t('platform.patient.primary-id', [], { locale })}: ${patient.mrn}`;
    const encounterName = i18n.global.t(`custom.menicon.encounter.type.${encounter.type}`, [], { locale });
    const prescription = `${i18n.global.t('custom.menicon.encounter.summary.prescription', [], { locale })}: ${journey.type} - ${
      journey.laterality
        ? i18n.global.t(`platform.eyes.${journey.laterality}-eye`, [], { locale })
        : i18n.global.t('platform.eyes.both-eyes', [], { locale })
    }`;
    const completedDate = `${i18n.global.t('custom.menicon.encounter.summary.completed', [], { locale })}: ${i18n.global.d(encounter.completed_at, 'dateTime', locale)}`;
    const completedBy = `${i18n.global.t('custom.menicon.encounter.summary.completed-by', [], { locale })}: ${encounter.last_activity?.user.given_name} ${encounter.last_activity?.user.family_name}`;
    return `${prescriptionDetails}\n\n${patientName}\n${dateOfBirth}\n${patientId}\n\n\n${encounterName}\n\n${prescription}\n${completedDate}\n${completedBy}`;
  };

export const getCopyableTextFromQuestionSummaryData =
  (sectionSummary: EncounterQuestionSummaryData[], locale = i18n.global.locale.value, title = '', groupStringAnswers = true): string =>
    `\n${title}\n\n${sectionSummary
      .map((summaryData, index) => {
        switch (summaryData.type) {
        case 'table':
          return `${summaryData.laterality && summaryData.laterality !== 'spacer' ?
            `${index ? '\n' : ''}${i18n.global.t(summaryData.laterality === 'both' ? 'platform.eyes.both-eyes' : `platform.eyes.${summaryData.laterality}-eye`, [], { locale })}\n` : ''}${formatTableAnswer(summaryData.value, index, locale, '\n', summaryData.description)}`;
        case 'grid':
          return `${(summaryData.label as string).endsWith('?') ? summaryData.label : `${summaryData.label}:`} ${formatGridAnswer(summaryData.value, locale)}`;
        case 'alert':
          return Array.isArray(summaryData.label) ? summaryData.label.join('\n') : summaryData.label;
        case 'heading':
          return `${index ? '\n' : ''}${summaryData.label}`;
        case 'string':
        default:
          return `${groupStringAnswers || !index ? '' : '\n'}${(summaryData.label as string).endsWith('?') ? summaryData.label : `${summaryData.label}:`} ${(summaryData as EncounterStringSummaryData).value}`;
        }
      }).join('\n')}`;


const formatTableAnswer =
  (answer: EncounterTableSummaryData['value'], itemIndex = 0, locale = i18n.global.locale.value, cellSeparator = '\n', description = null): string => answer.rows
    .map((row) => row
      .map((cellValue: string | { value: string }, index) => {
        const column = answer.columns[index];
        const columnLabel = typeof column === 'string' ? column : column?.value;
        const value = cellValue === null || typeof cellValue === 'undefined' ? i18n.global.t('platform.common.not-answered', [], { locale }) :
          typeof cellValue === 'object' ? cellValue?.value : cellValue;
        if (!columnLabel) {
          return '';
        }
        if (typeof column === 'string') {
          return `${columnLabel}: ${value}`;
        }
        return `${itemIndex ? '\n' : ''}${columnLabel}${value ? ` (${value})${index === 0 && description ? `\n${description}` : ''}` : ''}`;
      })
      .filter(Boolean)
      .join(cellSeparator)
    )
    .join('\n');
const formatGridAnswer = (answer: EncounterGridSummaryData['value'], locale = i18n.global.locale.value): string =>
  answer
    .filter((item) => item.value)
    .map((item) => `${item.icon && i18n.global.te(`platform.eyes.${item.icon}`) ? `${i18n.global.t(`platform.eyes.${item.icon}`, [], { locale })}: ` : ''}${item.value}${item.description ? ` (${item.description})` : ''}`)
    .join('; ');
export const formSchemaSectionToCopyableText =
  (section: FormSection | FormItem, formSubmissionId: string, locale = i18n.global.locale.value): string => {
    const smartForm = useSmartFormStore();
    const showAnswer = (item: FormItem) => !['group', 'component', 'spacer', 'list-item', 'alert', 'section'].includes(item.type) &&
      smartForm.getItemConditionsMet(formSubmissionId, item.conditions);
    const reducer = (acc: string, item: FormItem) => {
      if (smartForm.getItemConditionsMet(formSubmissionId, item.conditions)) {
        if ((item.type === 'repeater' || item.component === 'custom/medication/MedicationQuestion') &&
          isRepeaterAnswer(smartForm.getAnswer(formSubmissionId, item.id))
        ) {
          const answer = formatFormSchemaAnswer(smartForm.answers[formSubmissionId]?.[item.id], item, locale);
          return `${acc}\n${item.label}\n${formatTableAnswer(answer, 0, locale, '; ')}`;
        }
        if (item.items?.length) {
          if (item.type === 'group' && item.layout === 'grid') {
            const answers = item.items
              .filter(showAnswer)
              .map((i) => {
                let prefix = i.props?.label ?? i.label ?? '';
                if (!prefix && i.props?.icon && i18n.global.te(`platform.eyes.${i.props.icon}`)) {
                  prefix = i18n.global.t(`platform.eyes.${i.props.icon}`, [], { locale });
                }
                const answer = formatFormSchemaAnswer(smartForm.answers[formSubmissionId]?.[i.id], i, locale);

                return prefix ? `${(prefix as string).endsWith('?') ? prefix : `${prefix}:`} ${answer}` : answer;
              }).join('; ');
            const label = item.label || item.props?.label ? item.label ?? item.props?.label : '';
            return `${acc}\n${(label as string).endsWith('?') ? label : `${label}:`} ${answers}`;
          } else if (showAnswer(item)) {
            const label = item.label || item.props?.label ? item.label ?? item.props?.label : '';
            return `${acc}\n${(label as string).endsWith('?') ? label : `${label}:`} ${formatFormSchemaAnswer(smartForm.answers[formSubmissionId]?.[item.id], item, locale)}${item.items.reduce(reducer, '')}`;
          }

          const heading = item.type === 'section' && item.heading ? `\n${item.heading}\n` : '';
          return `${acc}\n${heading}${item.items.reduce(reducer, '')}`;
        }
        if (showAnswer(item)) {
          const label = item.label || item.props.label;
          return `${acc}\n${(label as string).endsWith('?') ? label : `${label}:`} ${formatFormSchemaAnswer(smartForm.answers[formSubmissionId]?.[item.id], item, locale)}`;
        }
      }
      return acc;
    };
    return `\n${section.heading}\n${section.items.reduce(reducer, '')}`;
  };
