import { GenericService } from '@/services/api/generic.service';
import { VirtualDoctorInstruction } from '@/custom/menicon/models';
import apiClient from '@/services/api/client/apiClient';

export class MeniconInstructionsService extends GenericService<VirtualDoctorInstruction> {
  constructor() {
    super('menicon/v1/virtual-doctor/instructions');
  }

  getEndpointUrl() {
    return `${window.location.origin}${apiClient.defaults.baseURL}/${this.endpoint}`;
  }
}
