import { GenericService } from './generic.service';
import { Encounter } from '@/models/episode-of-care/encounter.model';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConsultType } from '@/models';
import apiClient from '@/services/api/client/apiClient';

export class EncounterService extends GenericService<Encounter> {

  constructor() {
    super('v1/encounters');
  }

  async resend(encounterId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.post(`${this.endpoint}/${encounterId}/resend-patient-letter`, params);
  }

  async getConsultTypes(params: AxiosRequestConfig = {}): Promise<AxiosResponse<ConsultType[]>> {
    return await apiClient.get('v1/consult-types', params);
  }
}
