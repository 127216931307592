import { Encounter, EncounterTypeMode } from '@/custom/menicon/models/encounter.model';
import { JourneyTypeName } from '@/custom/menicon/models/journey-type.model';
import { Laterality } from '@/custom/menicon/models/laterality';
import { PrescriptionRevision } from '@/custom/menicon/models/prescription.model';

export interface EndedReason {
  code: EndedReasonCode;
  description?: string;
}

export interface Journey {
  id: string;
  type?: JourneyTypeName;
  patient_id: string;
  mode: EncounterTypeMode;
  organisational_unit_id: string;
  ended_at?: string;
  ended_reasons?: EndedReason[];
  ended_further_details?: string;
  next_corrected_laterality?: Laterality | null;
  current_prescriptions?: Record<Laterality, PrescriptionRevision | null>;
  encounters?: Array<Encounter>;
  draft_encounters?: Array<Encounter>;
  complete_encounters?: Array<Encounter>;
  laterality?: Laterality | null;
  dominant_eye?: Laterality | null;
  last_activity?: Encounter & {
    last_updated_at: string;
  };
}

export enum EndedReasonCode {
  END_PROGRAM_DEFAULT = 2000,
  END_PROGRAM_INAPPROPRIATE_FIT,
  END_PROGRAM_VA_INSUFFICIENT,
  END_PROGRAM_PATIENT_REACTION_TO_LENS,
  END_PROGRAM_PATIENT_DOES_NOT_LIKE_LENS,
  END_PROGRAM_OTHER_REASON = 2099
}

export enum JourneyStatus {
  all = 0,
  active = 1,
  ended = 2
}

export interface EndPrescriptionValue {
  reasons: EndedReasonCode[];
  details: string;
}
