import { Study } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig } from 'axios';

export class ZephyrService {
  private endpoint: string;

  constructor(patientId: string) {
    this.endpoint = `v1/patients/${patientId}/studies`;
  }

  async getStudies(params: AxiosRequestConfig = {}): Promise<Array<Study>> {
    return (await apiClient.get(this.endpoint, params)).data;
  }
}
