import apiClient from '@/services/api/client/apiClient';
import {
  Correction,
  MeniconBloomDayCorrectionRequestBody,
  MeniconBloomNightCorrectionRequestBody,
  MeniconImageDetailsLegendItem,
  MeniconLens,
  MeniconLensDifferenceMapsRequestBody,
  MeniconLensListItem,
  MeniconLensListRequestBody,
  MeniconLensPropertiesRequestBody,
  MeniconLensRecalculationRequestBody,
  MeniconLensRecalculationResponse,
  MeniconLensDetails
} from '@/custom/menicon/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class MeniconLensOrderService {
  endpoint = 'menicon/v1/lens';

  async getLensList(
    params: MeniconLensListRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<Array<MeniconLensListItem>> {
    return (await apiClient.get(this.endpoint, { ...config, params, timeout: 200000 })).data;
  }

  async getLensProperties(
    params: MeniconLensPropertiesRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MeniconLens>> {
    return await apiClient.get(`${this.endpoint}/${lensId}`, {
      ...config,
      params,
      timeout: 200000
    });
  }

  async getLensDetails(
    lensId: string,
    params: {
      organisational_unit_id: string;
    },
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MeniconLensDetails>> {
    return await apiClient.get(`${this.endpoint}/${lensId}/details`, {
      ...config,
      params,
      timeout: 200000
    });
  }

  async getBloomNightLensCorrections(
    params: MeniconBloomNightCorrectionRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<Correction>> {
    return await apiClient.get(`${this.endpoint}/${lensId}/bloom-night-corrections`, {
      ...config,
      params: {
        ...params,
        ...(params.lens_fitting ? {
          lens_fitting: JSON.stringify(params.lens_fitting)
        } : {})
      },
      timeout: 200000
    });
  }

  async getBloomDayLensCorrections(
    params: MeniconBloomDayCorrectionRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<Correction>> {
    return await apiClient.get(`${this.endpoint}/${lensId}/bloom-day-corrections`, {
      ...config,
      params: {
        ...params,
        ...(params.lens_fitting ? {
          lens_fitting: JSON.stringify(params.lens_fitting)
        } : {})
      },
      timeout: 200000
    });
  }

  async getDifferenceMaps(params: MeniconLensDifferenceMapsRequestBody, config: AxiosRequestConfig): Promise<string> {
    const data = (await apiClient.get('menicon/v1/difference-map', {
      ...config,
      params,
      timeout: 200000,
      responseType: 'blob'
    })) as BlobPart;

    return URL.createObjectURL(new Blob([data]));
  }

  async getDifferenceMapLegend(params: MeniconLensDifferenceMapsRequestBody, config: AxiosRequestConfig):
    Promise<MeniconImageDetailsLegendItem[]> {
    return (await apiClient.get('menicon/v1/difference-map-legend', {
      ...config,
      params,
      timeout: 200000
    })).data;
  }

  async getLensRecalculation(
    params: MeniconLensRecalculationRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<MeniconLensRecalculationResponse> {
    return await apiClient.get(`${this.endpoint}/recalculation`, {
      ...config,
      params: {
        ...params,
        changed_params: JSON.stringify(params.changed_params),
        current_params: JSON.stringify(params.current_params)
      },
      timeout: 200000
    });
  }
}
