import Pusher from 'pusher-js';
import Echo, { PresenceChannel } from 'laravel-echo';
import tokenService from '@/services/token.service';

export default class PusherService {
  instance: Echo;

  constructor() {
    this.instance = new Echo({
      broadcaster: 'pusher',
      Pusher,
      key: 'uup_pusher_key',
      cluster: 'ap4',
      wsHost: `${window.location.hostname}`,
      wsPath: '/broadcasting',
      wsPort: process.env.VUE_APP_ENV === 'dev' ? 9100 : 443,
      forceTLS: process.env.VUE_APP_ENV !== 'dev',
      disableStats: true,
      authEndpoint: '/api/broadcasting/auth',
      auth: {
        headers: {
          Authorization: `Bearer ${tokenService.getToken()}`
        }
      }
    });
  }

  join(channelName: string): PresenceChannel {
    return this.instance.join(channelName);
  }

  leave(channelName: string): void {
    this.instance.leave(channelName);
  }

  disconnect(): void {
    this.instance.disconnect();
  }
}
