import { GenericService } from '@/services/api/generic.service';
import { DomainUserType } from '@/custom/menicon/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class DomainUserRoleService extends GenericService<DomainUserType> {
  constructor(domainId: string, userId: string) {
    super(`/menicon/v1/domains/${domainId}/users/${userId}/roles`);
  }

  async setRoleAssignments(ids: Array<number>, params: AxiosRequestConfig = {}): Promise<AxiosResponse<DomainUserType[]>> {
    return await apiClient.put(this.endpoint, { ids }, params);
  }
}
