import { MeniconImageDetails, MeniconImageDetailsRequest } from '@/custom/menicon/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class MeniconImageDetailsService {
  endpoint = 'menicon/v1/image-details';

  async getImageDetails(params: MeniconImageDetailsRequest, config: AxiosRequestConfig = {}):
    Promise<AxiosResponse<MeniconImageDetails>> {
    return await apiClient.get(this.endpoint, {
      ...config,
      params: {
        ...params,
        ...config.params
      }
    });
  }
}
