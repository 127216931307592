import { EncounterTypeName } from '@/custom/menicon/models';
import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';

export const patientHistorySectionIds = {
  both: '787216b4-c692-43d0-bbdb-ca905ce55519',
  right: 'e090607c-73eb-4840-b7f1-d4b756fd4448',
  left: 'dbe33f47-1d94-4be2-b9f8-65a98604ed50'
};

export const slitLampWithContactLensesSectionIds = {
  [EncounterTypeName.BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT]: '4121b65a-a256-464d-9929-e90c5d94d9c2',
  [EncounterTypeName.BLOOM_DAY_3_OR_9_MONTHS_HEALTH_ASSESSMENT]: '4121b65a-a256-464d-9929-e90c5d94d9c2',
  [EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT]: '5e13296f-1367-4dbf-8f46-5044e43878d9',
  [EncounterTypeName.BLOOM_DAY_LENS_COLLECTION]: '817a86bc-dfb2-46d7-8c15-0aabba7646dd'
};

export const contactLensDiameterSectionId = 'cc39ea2b-0359-4f3f-9e0a-c8d9701f7b2e';

export const hygieneSectionId = '15d22730-924f-4e6b-8f88-24f353b9972a';

export const TEXT_AREA_CHARACTERS_LIMIT = 1000;

export const yesNoOptions = (locale = i18n.global.locale.value): IOption[] => [
  {
    label: i18n.global.t('platform.common.yes', [], { locale }),
    value: true
  },
  {
    label: i18n.global.t('platform.common.no', [], { locale }),
    value: false
  }
];

export const encounterWithDominantEyeQuestion = [
  EncounterTypeName.BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT,
  EncounterTypeName.QUICK_LENS_EFFICACY_ASSESSMENT
];
