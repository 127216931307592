// I am unsure of the best way to load shaders from files... But this is sufficient for now.
const fragmentShaders: { [K: string]: string } = {
  redFree: `
precision mediump float;

uniform sampler2D u_image;
varying vec2 v_texCoord;

void main() {
    vec4 pix = texture2D(u_image, v_texCoord);
    float v = .7 * pix.g + .3 * pix.b;
    gl_FragColor = vec4(v,v,v,1.0);
}
  `,
  falseColour: `
precision mediump float;

uniform sampler2D u_image;
varying vec2 v_texCoord;

#define PI 3.14159265358979

vec3 cubehelix(float x) { // translated from the FORTRAN code by Dave Green at https://www.mrao.cam.ac.uk/~dag/CUBEHELIX/cubhlx.f
    float hue = 3.0;
    float angle = 2.0 * PI * (0.5/3.0 + 1.0 - 1.5 * x);
    // float angle = 2.0 * PI * (1.0 + x);
    x = x * 1.0;
    float amp = hue * x * (1.0 - x) / 2.0;

    float r = x + amp*(-0.14861*cos(angle)+1.78277*sin(angle));
    float g = x + amp*(-0.29227*cos(angle)-0.90649*sin(angle));
    float b = x + amp*(+1.97294*cos(angle));
    return vec3(r, g, b);
}

void main() {
    vec4 pix = texture2D(u_image, v_texCoord);
    vec3 rgb = cubehelix(pix.r);
   gl_FragColor = vec4(rgb, 1.0);
}
  `
};

const vertexShaders: { [K: string]: string } = {
  image: `
attribute vec2 a_position;
attribute vec2 a_texCoord;
varying vec2 v_texCoord;

void main() {
   gl_Position = vec4(a_position.x, a_position.y, 0, 1);
   v_texCoord = vec2(a_texCoord.s, 1.0 - a_texCoord.t);
}
  `
};

/*
  This is simply for a proof of concept. Improvements include:
  - caching shaders and/or programs
  - being able to chain programs together using framebuffers
  - better handling of browsers that cannot handle webgl
*/
export class WebGlService {
  canvas: HTMLCanvasElement;
  gl: WebGLRenderingContext | null = null;
  shaderProgram: WebGLProgram | null = null;

  positionBuffer: WebGLBuffer | null = null;
  texcoordBuffer: WebGLBuffer | null = null;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.shaderProgram = null;
    this.positionBuffer = null;
    this.texcoordBuffer = null;

    try {
      this.gl = canvas.getContext('webgl', { preserveDrawingBuffer: true }) ?? null;
    } catch (e) {
      console.warn('issue setting up webgl, your browser probably does not support it');
      this.gl = null;
    }

    if (this.gl !== null) {
      this.positionBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        new Float32Array([-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0]),
        this.gl.STATIC_DRAW
      );

      this.texcoordBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0]),
        this.gl.STATIC_DRAW
      );
    }
  }

  createShaderFromTemplate(templateKey: string, type: string): WebGLShader | null {
    if (this.gl === null) {
      return null;
    }

    let source = '';
    let shader: WebGLShader | null;
    if (type === 'vertex') {
      shader = this.gl.createShader(this.gl.VERTEX_SHADER);
      source = vertexShaders[templateKey];
    } else if (type === 'fragment') {
      shader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
      source = fragmentShaders[templateKey];
    } else {
      console.warn(`Invalid shader type: ${type}`);
      return null;
    }

    if (shader !== null) {
      this.gl.shaderSource(shader, source);
      this.gl.compileShader(shader);

      if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
        console.warn(`An error occurred compiling shader: ${this.gl.getShaderInfoLog(shader)}`);
        return null;
      }
    }

    return shader;
  }

  createProgram(vertexShader: WebGLShader | null, fragmentShader: WebGLShader | null): WebGLProgram | null {
    if (this.gl && vertexShader && fragmentShader) {
      const program = this.gl.createProgram();
      if (program) {
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        if (!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
          console.warn(`Unable to initialize the shader program: ${this.gl.getProgramInfoLog(program)}`);
          return null;
        }

        return program;
      }
    }
    return null;
  }

  setProgram(vertexShaderKey: string, fragmentShaderKey: string) {
    const vertexShader = this.createShaderFromTemplate(vertexShaderKey, 'vertex');
    const fragmentShader = this.createShaderFromTemplate(fragmentShaderKey, 'fragment');
    this.shaderProgram = this.createProgram(vertexShader, fragmentShader);
  }

  setSource(canvas: HTMLCanvasElement) {
    if (this.gl === null) {
      return;
    }

    const texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);

    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, canvas);

    this.canvas.width = canvas.width;
    this.canvas.height = canvas.height;
  }

  draw() {
    if (this.gl === null || this.shaderProgram === null) {
      return;
    }

    // In the future this will allow us to have a pipeline of programs through framebuffers
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);
    this.gl.clearColor(0, 0, 0, 0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.gl.useProgram(this.shaderProgram);

    const positionLocation = this.gl.getAttribLocation(this.shaderProgram, 'a_position');
    this.gl.enableVertexAttribArray(positionLocation);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.vertexAttribPointer(positionLocation, 2, this.gl.FLOAT, false, 0, 0);

    const texcoordLocation = this.gl.getAttribLocation(this.shaderProgram, 'a_texCoord');
    this.gl.enableVertexAttribArray(texcoordLocation);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);
    this.gl.vertexAttribPointer(texcoordLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
  }
}
