import { DifferenceMapsType } from '@/custom/menicon/models/difference-maps';
import { Laterality } from '@/custom/menicon/models/laterality';
import { ImageSeries } from '@/models/study.model';

export interface DifferenceMapsTypeData {
  map: string;
  legend: MeniconImageDetailsLegendItem[];
}

export type DifferenceMapsData = Record<Laterality, Record<DifferenceMapsType, DifferenceMapsTypeData>>;

export interface MeniconImageDetailsRequest {
  organisational_unit_id: string;
  mls_id: string;
}

export interface MeniconImageDetailsLegendItem {
  displayOrder: number;
  hexColor: string;
  value: number;
}

export enum MeniconImageDetailsScore {
  BAD = 'Bad',
  GOOD = 'Good',
  UNKNOWN = 'Unknown',
}

export interface MeniconImageDetails {
  scoreRings: {
    visibleSectors: number[];
  };
  legend: Record<DifferenceMapsType, MeniconImageDetailsLegendItem[]>;
  scoringDetails: {
    scorePercentage: number;
    topographyDataScore: MeniconImageDetailsScore;
  }
}

export type ImageSeriesWithLegend = ImageSeries & { legend?: Record<DifferenceMapsType, MeniconImageDetailsLegendItem[]> };
