import { User } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '../client/apiClient';
import { GenericService } from '../generic.service';

export class AdminUserService extends GenericService<User> {
  constructor() {
    super('v1/admin/users');
  }

  async resetMFA(userId: string, params: AxiosRequestConfig = {}): Promise<{ message: string }> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-mfa`, params);
  }

  async removeDomains(userId: string, params: AxiosRequestConfig = {}): Promise<void> {
    return await apiClient.post(`${this.endpoint}/${userId}/remove-domains`, params);
  }

  async removeMeniconDomains(userId: string, params: AxiosRequestConfig = {}): Promise<void> {
    return await apiClient.post(`${this.endpoint}/${userId}/remove-menicon-domains`, params);
  }

  async resetPassword(userId: string): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-password`);
  }

  async resendInvite(userId: string): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${userId}/resend-invite`);
  }
}
