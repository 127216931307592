export default new class FilterStorageService {
  addToObject(key: string, id: string, value: string) {
    const current = JSON.parse(this.get(key)) ?? {};
    this.set(key, JSON.stringify({ ...current, [id]: value }));
  }

  getFromObject(key: string, id: string) {
    return JSON.parse(this.get(key))?.[id] ?? null;
  }

  set(key: string, value: string) {
    localStorage.setItem(`${key}-filter`, value);
  }

  get(key: string) {
    return localStorage.getItem(`${key}-filter`);
  }

  exists(key: string) {
    return !!localStorage.getItem(`${key}-filter`);
  }

  remove(key: string | string[]) {
    if (Array.isArray(key)) {
      key.forEach((k) => localStorage.removeItem(`${k}-filter`));
    } else {
      localStorage.removeItem(`${key}-filter`);
    }
  }
}();
