import { Order, MeniconLensOrderRequestBody } from '@/custom/menicon/models';
import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig } from 'axios';
import { GenericService } from '@/services/api/generic.service';

export class MeniconOrderService extends GenericService<Order> {

  constructor() {
    super(`/menicon/v1/orders`);
  }

  async createOrder(body: MeniconLensOrderRequestBody, params: AxiosRequestConfig = {}): Promise<string> {
    return await apiClient.post(this.endpoint, body, params);
  }

  async getReceipt(orderId: string, config: AxiosRequestConfig = {}): Promise<string> {
    const data: Blob = await apiClient.get(`${this.endpoint}/${orderId}/receipt`, {
      ...config,
      responseType: 'blob'
    });
    return window.URL.createObjectURL(new Blob([data]));
  }
}
