import 'reflect-metadata';
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import { i18n } from '@/i18n/i18n';
import scrollLock from '@/directives/scroll-lock.directive';
import VueClickAway from 'vue3-click-away';
import InlineSvg from 'vue-inline-svg';
import VuePortal from 'portal-vue';
// Assets
import './assets/tailwind.css';
import './assets/flags.css';
// Sentry error tracking
import * as Sentry from '@sentry/vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { beforeEach } from '@/router/middleware';
import interceptors from '@/services/api/client/interceptors';
import allow from '@/directives/allow.directive';
import feature from '@/directives/feature.directive';
import AWS from 'aws-sdk/global';
import mixpanel from 'mixpanel-browser';

const app = createApp(App);
const pinia = createPinia();

// Add middleware
router.beforeResolve(
  (routeTo: RouteLocationNormalized, routeFrom: RouteLocationNormalized, next: NavigationGuardNext) =>
    beforeEach(routeTo, routeFrom, next)
);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(VueClickAway);
app.use(VuePortal);
app.component('InlineSvg', InlineSvg);
interceptors;

app.directive('allow', allow);
app.directive('feature-flagged', feature);
app.directive('scroll-lock', scrollLock);

// Temporary solution to tweak behaviour per-customer
app.config.performance = true;

router.isReady().then(() => app.mount('#app'));

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  ignoreErrors: [
    'Non-Error exception captured'
  ],
  integrations: [new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router)
  })],
  environment: process.env.VUE_APP_LOG_ENV ?? 'unspecified'
});

if (process.env.VUE_APP_AWS_COGNITO_IDENTITY_ID) {
  AWS.config = new AWS.Config({
    region: process.env.VUE_APP_AWS_KINESIS_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_ID ?? ''
    })
  });
}

if (process.env.VUE_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    ignore_dnt: true
  });
}
