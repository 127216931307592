import { MeniconNotification } from '@/custom/menicon/models/menicon-lens-params.model';
import { KUnit } from '@/custom/menicon/models/units';
export interface MeniconCornealDetailsRequestModel {
    has_topography: 0 | 1 | boolean;
    organisational_unit_id?: string;
    laterality: string;
    r_mls_id?: string;
    r_hvid?: number;
    r_sphere?: number;
    r_cylinder?: number;
    r_axis?: number;
    r_flat_k?: number;
    r_steep_k?: number;
    r_flat_k_axis?: number;
    l_mls_id?: string;
    l_hvid?: number;
    l_sphere?: number;
    l_cylinder?: number;
    l_axis?: number;
    l_flat_k?: number;
    l_steep_k?: number;
    l_flat_k_axis?: number;
    k_unit?: KUnit;
}

type MeniconCornealDetailsSectionName = 'keratometry' |'periphery' | 'peripheryZernike' | 'shape' | 'restAstigmatism' | 'sclera' | 'miscellaneous';

export type MeniconCornealDetailsResponse = {
    [sectionName in MeniconCornealDetailsSectionName]?: {
        [itemName: string]: CornealPropertyDetail;
    };
}

export enum UnitOfMeasurement{
    DEGREES = 'Degrees',
    MILLIMETERS = 'Millimeters',
    DIOPTER = 'Diopter',
    PERCENTAGE = 'Percentage',
    NOT_AVAILABLE = '˚'
}

export interface Range {
    min: number;
    max: number;
}

export interface CornealPropertyDetail {
    value: any;
    ranges?: Range[];
    unit: UnitOfMeasurement;
    notifications?: MeniconNotification[];
}
