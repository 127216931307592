import { Worklist } from '@/models/worklist.model';
import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { FilterTag, IbdProgramPayload } from '@/models';

export default class WorklistService extends GenericService<Worklist> {

  constructor() {
    super('v1/worklists');
  }

  async getWorklistTags(
    pathway: string,
    params: AxiosRequestConfig = {}
  ): Promise<FilterTag[]> {
    return (await apiClient.get(`${this.endpoint}/${pathway}/tags`, params)).data ?? [];
  }

  async addPatientToIbdPathway(
    pathway: string,
    patientId: string,
    model: IbdProgramPayload,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<IbdProgramPayload>> {
    return await apiClient.post(
      `${this.endpoint}/${pathway}/patients/${patientId}/episodes`,
      model,
      params
    );
  }
}
