import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';

export const DEFAULT_ADDITIONAL_CORRECTION = '0.00';
export const TRIAL_LENS_PARAMETER_NAME = 'TrialLens';
export const MATERIAL_PARAMETER_NAME = 'Material';
export const COMPRESSION_FACTOR_PARAMETER_NAME = 'RegressionCompensation';

export enum REORDER_REASONS {
  LOST_LENS = 'Lost lens',
  BROKEN_LENS = 'Broken lens',
  SCRATCHED_OR_DAMAGED_LENS = 'Scratched or damaged lens',
  NEED_EXTRA_LENS = 'Need extra lens',
  OTHER_REASON = 'Other reason',
}

export const OTHER_REASON_VALUE = 'Other reason';

export const reorderReasonsOptions = (locale = i18n.global.locale.value): IOption[] => [
  {
    label: i18n.global.t('custom.menicon.encounter.reason-for-order.lost-lens', [], { locale }),
    value: REORDER_REASONS.LOST_LENS
  },
  {
    label: i18n.global.t('custom.menicon.encounter.reason-for-order.broken-lens', [], { locale }),
    value: REORDER_REASONS.BROKEN_LENS
  },
  {
    label: i18n.global.t('custom.menicon.encounter.reason-for-order.scratched-or-damaged-lens', [], { locale }),
    value: REORDER_REASONS.SCRATCHED_OR_DAMAGED_LENS
  },
  {
    label: i18n.global.t('custom.menicon.encounter.reason-for-order.need-extra-lens', [], { locale }),
    value: REORDER_REASONS.NEED_EXTRA_LENS
  },
  {
    label: i18n.global.t('custom.menicon.encounter.reason-for-order.other-reason', [], { locale }),
    value: REORDER_REASONS.OTHER_REASON
  }
];
