import { Laterality } from '@/custom/menicon/models/laterality';
import { User } from '@/models/user.model';

export interface PrescriptionParams {
  lensId: string;
  lensName: string;
  lensCode: number;
  materialName: string;
  materialCode: string;
  lensParameters: {
    [key: string]: string;
  };
}

export interface Prescription {
  id: string;
  laterality: Laterality;
  parameters: PrescriptionParams;
  order_item?: {
    quantity: string;
    unit_of_measurement: string;
  };
  updated_at: string;
}

export interface PrescriptionRevisionParams extends PrescriptionParams {
  changedParameters: Record<string, string>;
}

export interface PrescriptionRevision extends Prescription {
  parameters: PrescriptionRevisionParams;
}

export interface RevisionResponse {
  prescriptions: Record<Laterality, PrescriptionRevision | null>;
  user: User;
  last_updated_at: string;
}

export enum PrescriptionHistoryFilter {
  all,
  right,
  left
}
