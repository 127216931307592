import { PrescriptionParams, PrescriptionRevision } from '@/custom/menicon/models/prescription.model';
import { VaUnit, KUnit } from '@/custom/menicon/models/units';
import { User } from '@/models/user.model';
import { Laterality } from '@/custom/menicon/models/laterality';
import { Organisation } from '@/models/organisational-unit.model';
import { DifferenceMapsPattern } from '@/custom/menicon/models/difference-maps';
import { FileAnswer } from '@/models/file.model';

interface LensRecommendationRequestData {
  quantity: number;
  unit_of_measurement: string;
  parameters: PrescriptionParams;
}

export interface LensRecommendation {
  id: string;
  laterality: Laterality;
  parameters: PrescriptionParams;
  quantity: number;
  unit_of_measurement: string;
}

export interface LensSummarySection {
  parameters: PrescriptionParams;
  unit_of_measurement: string;
  quantity: number;
  laterality: Laterality;
}

export enum EncounterRequestedAdviceStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface EncounterRequestedAdvice {
  id: string;
  request_reason: string;
  files?: FileAnswer[];
  reviewer?: User;
  cancelled_by?: User;
  created_at: string;
  completed_at: string | null;
  ended_at?: string;
  encounter_id?: string;
  request_id: string;
  status: EncounterRequestedAdviceStatus;
  patient_age: number;
  user_organisation?: Organisation;
  user?: User;
  encounter: Encounter;
  advice_note?: string;
  type?: string;
  lens_recommendations?: LensRecommendation[] | {
    right?: LensRecommendationRequestData;
    left?: LensRecommendationRequestData;
  };
  original_answers?: EncounterRequestOriginalAnswer[];
}

export interface EncounterRequestOriginalAnswer {
  id?: string,
  request_id?: string;
  r_is_expected_treatment_zone?: boolean;
  r_is_expected_centration?: boolean;
  r_pattern?: DifferenceMapsPattern;
  l_is_expected_treatment_zone?: boolean;
  l_is_expected_centration?: boolean;
  l_pattern?: DifferenceMapsPattern;
  r_mls_id?: string;
  r_flat_k?: string;
  r_steep_k?: string;
  r_flat_k_axis?: string;
  l_mls_id?: string;
  l_flat_k?: string;
  l_steep_k?: string;
  l_flat_k_axis?: string;
}


export interface Encounter {
  id: string;
  type: EncounterTypeName;
  r_visual_acuity?: string;
  r_sphere?: string;
  r_cylinder?: string;
  r_axial_length?: string;
  r_mls_id?: string;
  r_hvid?: string;
  r_flat_k?: string;
  r_steep_k?: string;
  r_flat_k_axis?: string;
  r_addition?: string;
  r_axis?: string;
  r_is_expected_treatment_zone?: boolean;
  r_is_expected_centration?: boolean;
  r_pattern?: DifferenceMapsPattern;
  l_visual_acuity?: string;
  l_sphere?: string;
  l_cylinder?: string;
  l_axial_length?: string;
  l_mls_id?: string;
  l_hvid?: string;
  l_flat_k?: string;
  l_steep_k?: string;
  l_flat_k_axis?: string;
  l_addition?: string;
  l_axis?: string;
  r_power_correction?: string;
  l_power_correction?: string;
  l_is_expected_treatment_zone?: boolean;
  l_is_expected_centration?: boolean;
  l_pattern?: DifferenceMapsPattern;
  is_cyclopegic_refraction?: boolean;
  combined_visual_acuity?: string;
  form_submission_id?: string;
  completed_at: string;
  updated_at: string;
  prescriptions?: Record<Laterality, PrescriptionRevision | null>;
  journey_id: string;
  last_activity?: {
    user: User;
    name: EncounterTypeName;
    completed_at: string;
  };
  order?: {
    id: string;
    name: string;
    status?: string;
  }; // order circular dependency
  journey?: any;
  laterality?: Laterality | null;
  dominant_eye?: Laterality | null;
  is_va_satisfactory?: boolean;
  l_is_va_satisfactory?: boolean;
  r_is_va_satisfactory?: boolean;
  r_distance_visual_acuity?: string;
  l_distance_visual_acuity?: string;
  combined_distance_visual_acuity?: string;
  va_unit: VaUnit;
  k_unit: KUnit;
  slit_lamp_conditions?: { [key in SlitLampCondition]?: SlitLampConditionGrading };
  is_slit_lamp_condition_none?: boolean;
  slit_lamp_details?: string;
  pre_fitting_slit_lamp_conditions?: { [key in SlitLampCondition]?: SlitLampConditionGrading };
  pre_fitting_is_slit_lamp_condition_none?: boolean;
  pre_fitting_slit_lamp_details?: string;
  locale?: string;
  reorder_reasons?: string[];
  reorder_details?: string;
  reorder_other_details?: string;
  lens_support_info?: EncounterRequestedAdvice;
  last_completed_step?: number;
  occupied?: boolean;
}

export enum SlitLampCondition {
  CORNEAL_STAINING = 'corneal-staining',
  CORNEAL_INFILTRATES = 'corneal-infiltrates',
  PAPILLARY_REACTION = 'papillary-reaction',
  CONJUNCTIVAL_HYPERMIA = 'conjunctival-hyperemia',
  CORNEAL_NEOVASCULARISATION = 'corneal-neovascularisation'
}

export interface SlitLampConditionGrading {
  r_grading?: number;
  l_grading?: number;
}

export enum EncounterTypeName {
  INITIAL_MEASUREMENT = 'initial-measurement',
  QUICK_INITIAL_MEASUREMENT = 'quick-initial-measurement',
  BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT = 'bloom-day-1-week-lens-efficacy-assessment',
  BLOOM_DAY_3_OR_9_MONTHS_HEALTH_ASSESSMENT = 'bloom-day-3-or-9-months-health-assessment',
  BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT = 'bloom-day-6-or-12-months-progress-assessment',
  BLOOM_DAY_LENS_COLLECTION = 'bloom-day-lens-collection',
  BLOOM_NIGHT_1_NIGHT_FOLLOW_UP = 'build-up-1-night',
  BLOOM_NIGHT_1_WEEK_FOLLOW_UP = 'build-up-1-week',
  BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT = 'bloom-night-3-month-check',
  BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT = 'bloom-night-progress-assessment',
  BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT = 'bloom-night-lens-efficacy-assessment',
  BLOOM_NIGHT_COLLECTION = 'bloom-night-collection',
  QUICK_DIFFERENCE_MAP_ASSESSMENT = 'quick-difference-map-assessment',
  QUICK_LENS_EFFICACY_ASSESSMENT = 'quick-lens-efficacy-assessment',
  LENS_REORDER = 'lens-reorder'
}

export enum EncounterTypeMode {
  GUIDED = 'guided',
  QUICK = 'quick',
  BOTH = 'both',
}

export enum EncounterStatus {
  all = 0,
  drafts = 1,
  orders = 2
}
