import axios from 'axios';
import { CustomerBranding } from '@/models';

export const getBrand = async (): Promise<CustomerBranding> => {
  try {
    const response = await axios.get('/');
    const branding = response.headers?.['bpm-brand'];
    return Object.values(CustomerBranding).includes(branding) ? branding : CustomerBranding.BPM;
  } catch (e) {
    return CustomerBranding.BPM;
  }
};
