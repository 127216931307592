import baseClient from './apiClient';
import { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import tokenService from '@/services/token.service';
import { handleErrorResponse } from './helper';
import router from '@/router';
import { useSessionStore } from '@/stores/session.store';

const interceptorConfig = baseClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const sessionStore = useSessionStore();
    config.headers.Authorization = `Bearer ${tokenService.getToken()}`;
    if (!router.currentRoute.value.meta?.isAdmin && sessionStore.currentOrganisationId) {
      config.headers['Current-Organisation'] = sessionStore.currentOrganisationId;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

const interceptorErrors = baseClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: any) => {
    handleErrorResponse(error);
    return Promise.reject(error);
  }
);

export default { interceptorConfig, interceptorErrors };
