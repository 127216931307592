export interface EyeData {
  initImage: string;
  initImageTime: string;
  lastImage: string;
  lastImageTime: string;
}

export interface DifferenceMapsModalImage {
  path: string;
  description: string;
  index: number;
}

export interface DifferenceMapsModalSection {
  pattern?: DifferenceMapsPattern;
  description: string[];
  images: DifferenceMapsModalImage[];
}

export enum DifferenceMapsType {
  AXIAL = 'axial',
  TANGENTIAL = 'tangential'
}

export enum DifferenceMapsPattern {
  A = 'a',
  B = 'b'
}

export enum MapTypeDescription {
  AXIAL = 'Axial Map',
  TANGENTIAL = 'Tangential Map'
}

export enum DifferenceMapsQuestionType {
  CENTRATION = 'centration',
  TREATMENT_ZONE = 'treatment-zone',
  PATTERN = 'pattern'
}
