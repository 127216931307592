import { GenericService } from '@/services/api/generic.service';
import { User } from '@/models';
import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class OrganisationActivateLensSupportService extends GenericService<User> {
  constructor(organisationId: string) {
    super(`/menicon/v1/expert-mode/organisations/${organisationId}/activate-lens-support`);
  }

  async sendRequest(params: AxiosRequestConfig = {}) {
    return await apiClient.post(this.endpoint, params);
  }
}
