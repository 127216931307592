import { Encounter } from '@/custom/menicon/models';
import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig } from 'axios';

export class JourneyEncounterService extends GenericService<Encounter> {

  constructor(journeyId: string) {
    super(`/menicon/v1/journeys/${journeyId}/encounters`);
  }

  async complete(encounterId: string, updates: Partial<Encounter> = {}, params: AxiosRequestConfig = {}): Promise<Encounter> {
    return await this.update(
      encounterId,
      {
        ...updates,
        completed_at: new Date().toISOString()
      },
      params
    );
  }
}
